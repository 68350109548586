import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'

// Components
import Seo from '../components/Seo'
import Header from '../components/Header/'
import PageHeader from '../components/Header/PageHeader'
import Curve from '../components/Curve'
import Footer from '../components/Footer'
import PortfolioCrumb from '../components/Portfolio/PortfolioCrumb'

const PortWrapper = styled.div`
  background: ${({ theme }) => theme.colors.ghost};
`

const PortContent = styled.div`
  margin: 0 auto;
  max-width: ${({ theme }) => theme.breakpoints.md};
  padding: ${({ theme }) => theme.spacings.large} ${({ theme }) => theme.spacings.medium};
`

const catSpread = category =>
  category.length === 1 ? category : Object.values(category).join(' • ')

const PortfolioItem = props => {
  const { title, excerpt, category, featureImg } = props.data.mdx.frontmatter

  return (
    <>
      <Seo
        title={title}
        description={excerpt}
        url={props.location.href}
        image={`${props.location.origin}${getSrc(featureImg)}`}
      />
      <Header />
      <PageHeader title={catSpread(category)} subTitle={title} />
      <Curve />
      <PortWrapper>
        <PortContent className="portDetails">
          <PortfolioCrumb category={category[0]} />
          <MDXRenderer>{props.data.mdx.body}</MDXRenderer>
        </PortContent>
      </PortWrapper>
      <Curve flip />
      <Footer />
    </>
  )
}

export const portQuery = graphql`
  query SinglePortQuery($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        slug
        excerpt
        category
        keywords
        featureImg {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`

export default PortfolioItem
