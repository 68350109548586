import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'

// React Icons
import { RiArrowDropRightLine } from 'react-icons/ri'
import { HiHome as HomeIcon } from 'react-icons/hi'

const BreadContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.medium};
  color: ${({ theme }) => theme.colors.spanish};
  display: flex;
`

const Crumbs = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.desktopSizes.p};
`

const Child = styled(RiArrowDropRightLine)`
  color: ${({ theme }) => theme.colors.davys};
  font-size: ${({ theme }) => theme.desktopSizes.h5};
`

const Home = styled(HomeIcon)`
  margin-top: 10px;
  align-items: center;
  color: ${({ theme }) => theme.colors.davys};
  font-size: ${({ theme }) => theme.desktopSizes.p};

  &:focus {
    color: ${({ theme }) => theme.colors.spanish};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.indigo};
    transition: ${({ theme }) => theme.animations.trans2};
  }
`

const BrandLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.spanish};

  text-decoration: underline;
  text-decoration-style: dashed;
  text-underline-offset: ${({ theme }) => theme.spacings.xSmall};
  text-decoration-color: ${({ theme }) => theme.colors.platinum};

  &:focus {
    color: ${({ theme }) => theme.colors.spanish};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.indigo};
    transition: ${({ theme }) => theme.animations.trans2};
    text-decoration-color: ${({ theme }) => theme.colors.spanish};
  }
`

const PortfolioCrumb = ({ category = false }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            localStorageName
          }
        }
      }
    `,
  )

  const localName = data.site.siteMetadata.localStorageName
  const portCategory = category =>
    typeof window !== 'undefined' ? localStorage.setItem(localName, category) : 'all'

  return (
    <>
      <BreadContainer>
        <Crumbs>
          <Link to={'/'}>
            <Home />
          </Link>
          <Child />
          <BrandLink to={'/portfolio'}>portfolio</BrandLink>
          {category && (
            <>
              <Child />
              <BrandLink to={'/portfolio'} onClick={portCategory(category)}>
                {category}
              </BrandLink>
            </>
          )}
        </Crumbs>
      </BreadContainer>
    </>
  )
}

export default PortfolioCrumb
